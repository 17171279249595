import React, { useState, useEffect, useContext } from "react";
import { Space, Button } from "antd";
import { Col, Row, Card, Select, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaFilter, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiCloseCircleFill,
  RiFileDownloadFill,
} from "react-icons/ri";

import api from "../../../service/api";

//components
import MainMenu from "../Menu";
import { CardKPI } from "../Card";

//charts
import LineChart from "../Charts/Line";
//charts
import NPS from "../Charts/NPS";
import NewBarChart from "../Charts/NewBar";

//data
//import dataJSON from "../data.json";

//redux
import {
  tabActive,
  menuOpen,
  setResponseLight,
  responseLight,
  setInfoModal,
} from "../../../store/dashboard/dashboard.slice";

//providers
import { Context } from "../../../providers/AuthProvider";

const Dashboard = () => {
  const { dashid } = useParams();
  let { token } = useContext(Context);

  const tab = useSelector(tabActive);
  const activeMenu = useSelector(menuOpen);
  const dataJSON = useSelector(responseLight);
  const dispatch = useDispatch();

  const [localLoad, setLocalLoad] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcelOpen, setIsModalExcelOpen] = useState(false);
  const [hasFile, setHasFile] = useState("");

  const [NPSFilter, setNPSFilter] = useState([]);
  const [UnidadeFilter, setUnidadeFilter] = useState([]);
  const [FaixaEtariaFilter, setFaixaEtariaFilter] = useState([]);

  //dados dos filtros
  const [itemsNPS, setItemsNPS] = useState([]);
  const [itemsUnidade, setItemsUnidade] = useState([]);
  const [itemsFaixa, setItemsFaixa] = useState([]);

  const getInitData = () => {
    api
      .post("http://127.0.0.1:8000/api/" + dashid, [], {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));

        //setando filtros
        //nps
        let filter0 = data.filter.NPS.map((u) => {
          return { value: u, label: u };
        });
        setItemsNPS(filter0);
        //unidade
        let filter1 = data.filter.Unidade.map((u) => {
          return { value: u, label: u };
        });
        setItemsUnidade(filter1);
        //faixa
        let filter4 = data.filter["Idade Aluno"].map((u) => {
          return { value: u, label: u };
        });
        setItemsFaixa(filter4);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const doFilter = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    dispatch(setResponseLight(null));
    setLocalLoad(true);
    api
      .post("http://127.0.0.1:8000/api/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        dispatch(setResponseLight(data));
      })
      .finally(() => {
        setLocalLoad(false);
      });
  };

  const getExcel = () => {
    let filterBase = [
      { Unidade: UnidadeFilter },
      { NPS: NPSFilter },
      { "Idade Aluno": FaixaEtariaFilter },
    ];

    api
      .post("http://127.0.0.1:8000/api/download/" + dashid, filterBase, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        const { data } = result;
        if (data.status) {
          setHasFile(data.file);
        } else {
          setHasFile("error");
        }
      })
      .catch(() => {
        setHasFile("error");
      });
  };

  //   useEffect(() => {
  //     getInitData();
  //     // eslint-disable-next-line
  //   }, []);

  //   useEffect(() => {
  //     if (isModalExcelOpen) {
  //       setTimeout(() => {
  //         getExcel();
  //       }, 1500);
  //     }
  //     // eslint-disable-next-line
  //   }, [isModalExcelOpen]);

  return (
    <>
      <MainMenu
        design="pw"
        items={[
          {
            icon: "smile",
            label: "NPS",
            href: "#nps",
          },
        ]}
      />
      <div
        id="dahboard-light"
        style={
          activeMenu
            ? { width: "calc(100% - 120px)", marginLeft: "120px" }
            : { width: "100%", marginLeft: "0px" }
        }
      >
        <div
          className="filter-box"
          style={
            activeMenu ? { width: "calc(100% - 120px)" } : { width: "100%" }
          }
        >
          <Button type="primary" icon={<FaFilter />}>
            &nbsp; Filtrar
          </Button>
        </div>
        {tab === "#nps" && (
          <>
            <Row>
              <Col span={12}>
                <CardKPI
                  label="Respostas"
                  value="37"
                  icon="users"
                  bgColor="#002A4E"
                />
              </Col>
              <Col span={12}>
                <CardKPI
                  label="NPS"
                  value="65"
                  icon="circles"
                  bgColor="#FFC400"
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Card
                  id="nps-dist"
                  style={{ margin: 10, height: "300px" }}
                  title="NPS Distribuição"
                  extra={
                    <>
                      <FaInfoCircle
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        title="Informativo"
                        onClick={() =>
                          dispatch(
                            setInfoModal({
                              type: "question",
                              content:
                                "De 0 a 10, o quanto você recomendaria a Wizard para algum familiar ou amigo?",
                            })
                          )
                        }
                      />
                      <FaQuestionCircle
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        title="Ajuda"
                        onClick={() =>
                          dispatch(
                            setInfoModal({
                              type: "nps",
                            })
                          )
                        }
                      />
                    </>
                  }
                >
                  <NPS data={{ detrator: 4, neutro: 0, promotor: 19 }} />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  style={{ margin: 10, height: "300px" }}
                  title="NPS Histórico"
                >
                  <LineChart
                    style={{ height: "210px" }}
                    data={{ dataLabel: "NPS", data: { "05/2024": "65" } }}
                  />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Card
                  style={{ margin: 10, height: "300px" }}
                  title="Tipo de instituição que estuda"
                >
                  <NewBarChart
                    dimension="Tipo de instituição que estuda"
                    survey_id="1076"
                    type_element="bar"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  style={{ margin: 10, height: "300px" }}
                  title="Idade Aluno"
                >
                  <NewBarChart
                    dimension="Idade Aluno"
                    survey_id="1076"
                    type_element="bar"
                  />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Card
                  style={{ margin: 10, height: "300px" }}
                  title="Tipo de instituição que estuda"
                >
                  <NewBarChart
                    dimension="Tipo de instituição que estuda"
                    survey_id="1076"
                    type_element="bar"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  style={{ margin: 10, height: "300px" }}
                  title="Idade Aluno"
                >
                  <NewBarChart
                    dimension="Idade Aluno"
                    survey_id="1076"
                    type_element="bar"
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
