import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";

import api from "../../../../service/api";

//providers
import { Context } from "../../../../providers/AuthProvider";

const NewBarChart = (props) => {
  let { survey_id, dimension, type_element, design } = props;

  let { token } = useContext(Context);

  const [setting, setSetting] = useState(null);
  const [dataChart, setDataChart] = useState(null);

  const getInitData = () => {
    api
      .post(
        "http://127.0.0.1:8000/api/response",
        {
          data: props,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        const { data } = result;

        setDataChart(data);
      });
  };

  // Função para encurtar os rótulos
  const truncateLabel = (label) => {
    return label.length > 15 ? `${label.substring(0, 12)}...` : label;
  };

  useEffect(() => {
    console.log('esperando');
    if (dataChart !== null) {
      console.log(dataChart);
      const values = Object.values(dataChart.bar.data);
      const delta = Math.max(...values) - Math.min(...values);

      let settings = {
        default: {
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                align: "start",
                clamp: true,
                anchor: "end",
                offset: -20,
                display: true,
                formatter: (value) =>
                  new Intl.NumberFormat("pt-BR").format(value),
              },
              tooltip: {
                callbacks: {
                  title: function (tooltipItem) {
                    const originalLabel = Object.keys(dataChart.bar.data)[
                      tooltipItem[0]["dataIndex"]
                    ];
                    return `${originalLabel}`;
                  },
                  label: function (tooltipItem) {
                    const originalLabel = Object.keys(dataChart.bar.data)[
                      tooltipItem["dataIndex"]
                    ];
                    return `${new Intl.NumberFormat("pt-BR").format(
                      dataChart.bar.data[originalLabel]
                    )}`;
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  lineWidth: 0,
                },
              },
              y: {
                display: false,
                max: Math.max(...values) + 0.2 * delta,
              },
            },
          },
          data: {
            datasets: [
              {
                maxBarThickness: 50,
                minBarLength: 10,
                borderColor: "#9A2376",
                backgroundColor: [
                  "#9A2376",
                  "#FFC400",
                  "#008D3F",
                  "#00A3DD",
                  "#FF1928",
                  "#8E0A14",
                  "#593959",
                  "#1BBF37",
                  "#F64827",
                  "#77429D",
                  "#FFA300",
                ],
              },
            ],
          },
        },
        relationalBarYellow: {
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                align: "start",
                clamp: true,
                anchor: "end",
                offset: -20,
                display: true,
              },
              legend: {
                position: "right",
              },
              title: {
                display: true,
                text: "Medidas",
                position: "right",
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const originalLabel = dataChart.bar.data[tooltipItem.label];
                    return `${tooltipItem.label}: ${originalLabel}`;
                  },
                },
              },
            },
            scales: {
              x: {
                display: true,
                grid: {
                  lineWidth: 0,
                },
              },
              y: {
                display: false,
                grid: {
                  color: "#aaa",
                  lineWidth: 1,
                },
                border: {
                  dash: [2, 10],
                },
              },
            },
          },
          data: {
            datasets: [
              {
                type: "line",
                borderWidth: 2,
                fill: false,
                borderColor: "#design",
                backgroundColor: "#9A2376",
                order: 0,
              },
              {
                type: "bar",
                maxBarThickness: 50,
                minBarLength: 10,
                borderColor: "#9A2376",
                backgroundColor: "#FFC400",
                order: 1,
              },
            ],
          },
        },
      };

      console.log(dataChart);
      let setDesign = settings[design] ? settings[design] : settings["default"];

      // Injeção de dados padrão
      if (design === undefined || design === null) {
        setDesign.data.labels = Object.keys(dataChart.bar.data).map(
          truncateLabel
        );
        setDesign.data.datasets[0].data = Object.values(dataChart.bar.data);
      } else if (design === "relationalBarYellow") {
        // Injeção de dados gráfico relacional amarelo
        setDesign.data.labels = Object.keys(dataChart.bar.data).map(
          truncateLabel
        );

        // Dados da barra
        setDesign.data.datasets[1].data = Object.values(dataChart.bar.data);
        setDesign.data.datasets[1].label = dataChart.bar.legend;

        // Dados da linha
        setDesign.data.datasets[0].data = Object.values(dataChart.line.data);
        setDesign.data.datasets[0].label = dataChart.line.legend;
      }

      console.log(setDesign);

      setSetting(setDesign);
      // eslint-disable-next-line
    }
  }, [dataChart]);

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      {dataChart === null && (
        <div>
          <span>Carregando dados...</span> <br />
          Isto pode levar alguns segundos!
        </div>
      )}
      {dataChart !== null && setting !== null && (
        <div>
          <Bar {...props} data={setting.data} options={setting.options} />
        </div>
      )}
    </>
  );
};

export default NewBarChart;
